import { Box, Paper, Stack } from "@mui/material";
import React, { useState } from "react";
import { IBalancingSummaryCategories, IBalancingSummaryData } from "../../../../shared/types/analytics/trading-analytics/IBalancingSummaryData";
import { useColourModeContext } from "../../../ColourMode";
import { DateFormatter } from "../../helpers/DateFormatter";

const BalancingSummaryChartTooltipRow: React.FC<IBalancingSummaryChartTooltipRow> = ({ name, value, color, square }) => {
    const [parser] = useState<DOMParser>(new DOMParser())
    return (
        <Box>
            <Stack direction="row" spacing={0.5} alignItems="center">
                <span style={{ backgroundColor: color, width: "20px", height: square ? "20px" : "2px" }}></span>
                <span>{parser.parseFromString(name, "text/html").body.innerHTML}: {value} MW</span>
            </Stack>
        </Box>
    );
}

interface IBalancingSummaryChartTooltipRow {
    name: string,
    value: string,
    color: string,
    square: boolean
}
const BalancingSummaryChartTooltip: React.FC<IBalancingSummaryChartTooltip> = ({ data, squareKeys, lineKeys, nameDict, coloursDict }) => {
    const colourMode = useColourModeContext();
    return (
            <Paper sx={{
                "borderRadius": 5,
                "border": (colourMode.isDarkMode ? "1px solid white" : "1px solid black"),
                px: 1,
                py: 0.5
            }}>
                <Box>Date (Local Time): {DateFormatter(data.dateTime)}</Box>
                {squareKeys.map((key) => {
                    const name = nameDict[key]
                    const color = coloursDict[key]
                    const value = data[key].toFixed(0)
                    return (
                        <BalancingSummaryChartTooltipRow key={"Balancing-Summary-" + name} name={name} value={value} color={color} square={true} />
                    )
                })}
                {lineKeys.map((key) => {
                    const name = nameDict[key]
                    const color = coloursDict[key]
                    const value = data[key].toFixed(0)
                    return (
                        <BalancingSummaryChartTooltipRow key={"Balancing-Summary-" + name} name={name} value={value} color={color} square={false} />
                    )
                })}
        </Paper>
    );
}
export interface IBalancingSummaryChartTooltip {
    data: IBalancingSummaryData,
    squareKeys: (keyof IBalancingSummaryCategories<any>)[]
    lineKeys: (keyof IBalancingSummaryCategories<any>)[]
    nameDict: IBalancingSummaryCategories<string>,
    coloursDict: IBalancingSummaryCategories<string>
}

export default BalancingSummaryChartTooltip;