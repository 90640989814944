import { Hexagon } from 'react-hexgrid';
import { CubicCoords } from '../../shared/types/shared/hex-grid';
import { AssetType, ISiteStatus } from '../../shared/types/ISiteStatus';
import { CellTower } from '@mui/icons-material';
import { HexagonMouseEventHandler } from 'react-hexgrid/lib/Hexagon/Hexagon';
import { HexIndicatorBar } from './HexIndicatorBar';
import { HexMultiLineText } from './HexMultiLineText';
import { HexIcon } from './HexIcon';
import { HexStatusText } from './HexStatusText';
import { HexBatteryStatus } from './HexBatteryStatus';
import { HiveColours } from '../../shared/utils/StatusHiveUtils';
import { SiteManualStatus, StatusHiveSiteData, StatusHiveTileStatus } from '../../shared/types/asset/status-hive';
import { useTheme } from '@mui/material';
import { common } from '@mui/material/colors';
import { useColourModeContext } from '../ColourMode';
import dispatchApi from '../../shared/api/DispatchApi';
import { useEffect, useState } from 'react';
import { useToastAlertContext } from '../ToastAlert';

export const StatusHiveTile: React.FC<IStatusHiveTileProps> = ({ coords, status, onClick, dispatchMode, updateStatus, flashSync }) => {
    const theme = useTheme();
    const { popToast } = useToastAlertContext();
    const contentColour = status.colour;
    const opacity = status.tileStatus === StatusHiveTileStatus.Idle ? 0.5 : 1;
    const displayFrequencyServices = status.assetType === AssetType.Battery;
    const { isDarkMode } = useColourModeContext();
    const dispatchButtonsX = 2

    const dispatchSite = async (newManualStatus: SiteManualStatus) => {
        return await dispatchApi.sendManualDispatchRequest(status.siteId, newManualStatus)
    }

    const handleOnClick = async () => {
        let newManualStatus = SiteManualStatus.On
        if (status.manualStart) {
            newManualStatus = SiteManualStatus.NotManual
        }
        var response = await dispatchSite(newManualStatus)
        if (response) {
            let newStatus = status
            newStatus.manualStart = newManualStatus === SiteManualStatus.On
            popToast("Dispatch signal to " + status.siteId + " sent")
            updateStatus(newStatus)
        }
        else {
            popToast("Failed to dispatch " + status.siteId)
        }
    }

    const handleOffClick = async () => {
        let newManualStatus = SiteManualStatus.Off
        if (status.manualStop) {
            newManualStatus = SiteManualStatus.NotManual
        }
        var response = await dispatchSite(newManualStatus)
        if (response) {
            let newStatus = status
            newStatus.manualStop = newManualStatus === SiteManualStatus.Off
            popToast("Dispatch signal to " + status.siteId + " sent")
            updateStatus(newStatus)
        }
        else {
            popToast("Failed to dispatch " + status.siteId)
        }
    }

    const onButtonColor = theme.palette.conradEnergyFreshGreen.dark
    const offButtonColor = theme.palette.error.light

    const [showDispatch, setShowDispatch] = useState<boolean>(false)

    useEffect(() => {
        setShowDispatch(dispatchMode && status.assetType === AssetType.Gas)
    }, [dispatchMode, status])

    return (
        <Hexagon q={coords.q} r={coords.r} s={coords.s} fill="hex-background" opacity={opacity} stroke={!status.flash || flashSync ? contentColour : "none"} strokeWidth={"0.01em"} className="data-hex" onClick={onClick}>
            <HexIcon Icon={status.assetIcon} fontSize={0.125} colour={contentColour} y={-0.44} tooltip={AssetType[status.assetType]} />

            <HexMultiLineText text={status.siteName || status.siteId} colour={contentColour} y={showDispatch ? -2.5 : -1.1} fontSize={0.09} fontWeight={800} />

            {showDispatch && !displayFrequencyServices && 
                <>
                    <HexMultiLineText onClick={handleOnClick} borderColor={onButtonColor} backgroundColor={status.manualStart ? onButtonColor : undefined} text={"On"} colour={isDarkMode ? common.white : common.black} x={dispatchButtonsX} y={-1} fontSize={0.09} fontWeight={800} />
                    <HexMultiLineText onClick={handleOffClick} borderColor={offButtonColor} backgroundColor={status.manualStop ? offButtonColor : undefined} text={"Off"} colour={isDarkMode ? common.white : common.black} x={0 - dispatchButtonsX} y={-1} fontSize={0.09} fontWeight={800} />
                </>
            }
            
            {displayFrequencyServices ?
                <HexBatteryStatus status={status} activeColour={contentColour} inactiveColour={HiveColours.Idle} y={2.25} />
                :
                <HexStatusText status={status} colour={status.tileStatus === StatusHiveTileStatus.Pending ? HiveColours.Running : contentColour} y={2.45} />
            }

            <HexIcon Icon={CellTower} fontSize={0.125} colour={status.commsIconData.colour} y={0.32} tooltip={status.commsIconData.tooltip} />

            <HexIndicatorBar
                x={0}
                y={0}
                width={0.75}
                height={0.05}
                fill={contentColour}
                value={status.activePowerExport || 0}
                maxValue={status.dispatchPlan || 0}
                fillHorizontally={true}
                valueTooltipLabel="Available Export:"
                unit={"MW"}
            />
        </Hexagon>
    );
}

export interface IStatusHiveTileProps {
    coords: CubicCoords;
    status: StatusHiveSiteData;
    onClick?: HexagonMouseEventHandler<SVGElement>;
    dispatchMode: boolean;
    updateStatus: (newStatus: ISiteStatus) => void
    flashSync: boolean;
}