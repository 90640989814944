import ApiRoot from "./ApiRoot";
import { getApiAccessToken } from "../auth/helpers";
import { SiteManualStatus } from "../types/asset/status-hive";

const rootUrl = process.env.REACT_APP_PORTAL_API_URL + "Dispatch"
const apiKey = process.env.REACT_APP_API_GATEWAY_KEY;

const sendManualDispatchRequest = async (siteID: string, newStatus: SiteManualStatus) => {
    const requestBody = {
        "siteID": siteID,
        "Signal": newStatus
    }
    const accessToken = await getApiAccessToken();
    return await ApiRoot.post<boolean>(`${rootUrl}/DispatchSite`, requestBody, { apiKey, accessToken });
}

const cancelAllManualDispatches = async () => {
    const requestBody = {
    }
    const accessToken = await getApiAccessToken();
    return await ApiRoot.post<string[]>(`${rootUrl}/CancelAllDispatches`, requestBody, { apiKey, accessToken });
}


const dispatchApi = {
    sendManualDispatchRequest,
    cancelAllManualDispatches
}

export default dispatchApi;