import { useEffect, useMemo, useState } from 'react';
import { Hexagon } from 'react-hexgrid';
import ionLogoWhite from '../../images/ion-plus-white.png';
import ionLogoBlack from '../../images/ion-plus-black.png';
import { HexGridData, HiveGenerationMode } from '../../shared/types/shared/hex-grid';
import { generateDataTiles, GetUKLayout, processData } from '../../shared/utils/StatusHiveUtils';
import { useGetAssetStatusQuery } from '../../shared/api/AssetApi';
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { Hive } from '@mui/icons-material';
import { useToastAlertContext } from '../../components/ToastAlert';
import HexContainer from '../../components/shared/hex-grid/HexContainer';
import { useColourModeContext } from '../../components/ColourMode';
import { common } from '@mui/material/colors';
import { IHexGridConfig, IHiveDimensions, getCurrentDimension } from '../../shared/utils/HexGridUtils';
import { StatusHiveDataTile, StatusHiveSiteData } from '../../shared/types/asset/status-hive';
import { DispatchButton } from '../../components/status-hive/DispatchButton';
import { usePortalContext } from '../../components/PortalWrapper';
import { UserPermissionCollection } from '../../shared/types/shared/auth/userDataTypes';
import { AssetPermissions } from '../../shared/types/shared/auth/permissionsTypes';
import { CancelDispatchButton } from '../../components/status-hive/CancelDispatchButton';
import { Typography } from '@mui/material';
import { IAdvancedModalOptions, useModalWindowContext } from '../../components/ModalWindow';
import { ISiteStatus } from '../../shared/types/ISiteStatus';
import dispatchApi from '../../shared/api/DispatchApi';


const StatusHivePage: React.FC = () => {
    const { openModal } = useModalWindowContext();
    const { popToast } = useToastAlertContext();
    const { isDarkMode } = useColourModeContext();
    const [dispatchMode, setDispatchMode] = useState<boolean>(false)
    const [flashSync, setFlashSync] = useState<boolean>(false)
    const [disptachModeAuthenticated, setDispatchModeAuthenitcated] = useState<boolean>(false)
    const { currentUser, signOut } = usePortalContext();

    useEffect(() => { // Disable scroll on load
        document.body.style.overflow = 'hidden';

        // Reenable scroll on unload
        return () => {
            document.body.style.overflow = 'unset';
        }
    }, []);

    // Configure API calls
    const statusPollingInterval = useMemo(() => 60 * 1000, []);
    const { data: statusList, isSuccess: statusesSucceeded, isLoading: statusesLoading } = useGetAssetStatusQuery(undefined, { pollingInterval: statusPollingInterval });

    const [siteData, setSiteData] = useState<ISiteStatus[]>([]);
    const [data, setData] = useState<StatusHiveSiteData[]>([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => { // Track when loading is complete
        if (!statusesLoading && statusesSucceeded) {
            setLoaded(true);
        }
    }, [statusesLoading, statusesSucceeded]);

    const gridConfig = useMemo((): IHexGridConfig => {
        return {
            heightOffset: -64,
        }
    }, []);

    const [dimensions, setDimensions] = useState<IHiveDimensions>(getCurrentDimension(gridConfig)); // Dimensions
    const [grid, setGrid] = useState<HexGridData>(new HexGridData(0, 0)); // All Grid Hexagons
    const [dataTiles, setDataTiles] = useState<StatusHiveDataTile[]>([]); // The tiles to place in the grid
    const [firstPass, setFirstPass] = useState(false);

    const updateDataTiles = (newStatusData: StatusHiveSiteData[], newDimensions: IHiveDimensions) => {
        const newGrid = new HexGridData(newDimensions.grid.gridWidth, newDimensions.grid.gridHeight);
        setGrid(newGrid);

        const result = generateDataTiles(newStatusData, newGrid, HiveGenerationMode.Clusters, true);
        setDataTiles(result.placed);

        if (result.failed.length > 0) {
            popToast(`Unable to place ${result.failed.length} tiles`, "warning", 2500);
        }

        setFirstPass(true);
    };

    useEffect(() => { // When window resizes, update all dimensions values
        const updateDimension = () => {
            const newDimensions = getCurrentDimension(gridConfig);
            setDimensions(newDimensions);
        }

        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        });
    }, [gridConfig]);

    useEffect(() => {
        setSiteData(statusList || []);
    }, [statusList]);

    useEffect(() => {
        const newData = processData(siteData || []);
        setData(newData);
    }, [siteData])

    useEffect(() => { // If data or dimensions change, update tiles
        updateDataTiles(data, dimensions);
    }, [data, dimensions]);

    useEffect(() => {
        if (dispatchMode) {
            setTimeout(() => {
            setDispatchMode(false)
            }, 180000)
        }
    }, [dispatchMode])

    const { tileRadius } = dimensions.grid;
    const { centre, topLeft, bottomLeft } = grid.getAnchorPoints();

    const updateData = (newData: ISiteStatus) => {
        let newStatusList = [...siteData]
        const index = newStatusList.findIndex(siteStatus => siteStatus.siteId === newData.siteId)
        if (index > -1 && index < newStatusList.length) {
            newStatusList[index] = newData
            setSiteData(newStatusList)
        }
    }


    const flashingBorderIntervalMS = 1000;

    // Keeps flashing tiles in sync with on another
    useEffect(() => {
        const interval = setInterval(() => {
            setFlashSync(!flashSync)
        }, flashingBorderIntervalMS);

        return () => clearInterval(interval);
    }, [flashSync])

    
    useEffect(() => {
        if (process.env.NODE_ENV !== "development" && currentUser !== undefined && currentUser.userName.toLowerCase() !== "emc@conradcloud.net") {
            const timer = setTimeout(async () => { await signOut() }, 10800000)

            return () => clearTimeout(timer);
        }
    }, [])

    const dispatchPerms: Partial<UserPermissionCollection> = {
        Asset: AssetPermissions.Admin
    }

    useEffect(() => {
        setDispatchModeAuthenitcated(currentUser !== undefined && currentUser.hasPermissions(dispatchPerms) && currentUser.userName.toLowerCase() !== "emc@conradcloud.net")
    }, [currentUser])

    const loadCancelOutagesModal = async () => {
        const confirmationComponent = (
            <Typography>You are about to cancel all manual instructions and revert all sites to their PN plans.<br /> Are you sure you wish to continue?</Typography>
        );

        const options: IAdvancedModalOptions = {
            title: "Cancel Manual Dispatches",
            contentComponent: confirmationComponent,
            yesText: "Yes",
            noText: "Cancel",
            fullWidth: true,
            maxWidth: 'sm',
            canSubmit: true,
            error: false
        }

        return await openModal(options);
    };

    const cancelAllManualDispatches = async () => {
        const shouldCancelDispatches = await loadCancelOutagesModal()
        if (shouldCancelDispatches) {
            const cancelledSites = await dispatchApi.cancelAllManualDispatches()
            if (cancelledSites.length > 0) {
                const newData: ISiteStatus[] = []
                siteData.forEach(site => {
                    let newSite = {...site}
                    if (cancelledSites.indexOf(newSite.siteId) > -1) {
                        newSite.manualStart = false
                        newSite.manualStop = false
                    }
                    newData.push(newSite)
                })
                setSiteData(newData)
            }
            popToast("Cancelled manual dispatches to " + cancelledSites.length + " sites")
            setDispatchMode(false)
        }
    }

    if (!firstPass) { // Gives the grid an opportunity to have calculated at least once before displaying anything
        return (<></>)
    }

    return (
        <HexContainer dimensions={dimensions}>
            {disptachModeAuthenticated && <DispatchButton location={topLeft} dispatchActive={dispatchMode} setDispatchActive={setDispatchMode} />}
            {dispatchMode && <CancelDispatchButton location={centre} onClick={cancelAllManualDispatches} />}
            {
                loaded ?
                    dataTiles.map((tile) => tile.graphic(dispatchMode, updateData, flashSync))
                :
                    GetUKLayout(centre).map((coords, index) => (
                        <Hexagon key={`load-${index}`} q={coords.q} r={coords.r} s={coords.s} fill="none" stroke={isDarkMode ? common.white : common.black} strokeWidth={"0.01em"} />
                    ))
            }

            {!dispatchMode &&
                <Hexagon q={centre.q} r={centre.r} s={centre.s} fill="none" stroke={isDarkMode ? common.white : common.black} strokeWidth={"0.01em"}>
                    <image className="pulsing" x={-(tileRadius / 2.5)} y={-(tileRadius / 2)} href={isDarkMode ? ionLogoWhite : ionLogoBlack} height={tileRadius} width={tileRadius}></image>
                </Hexagon>
            }
        </HexContainer>
    );
}

const StatusHivePageConfig: IPortalRouteOptions = {
    relativeUrl: "status-hive",
        navDisplay: {
        title: "Status Hive",
        icon: <Hive />
    },
    page: {
        element: <StatusHivePage />,
        disableGutters: true,
        forceOverlayNav: true,
    },
    hideOnMobile: true
}

export default StatusHivePageConfig;
