import { Paper, Stack } from "@mui/material";
import React from "react";
import { ISystemSummaryCategories, ISystemSummaryData, ISystemSummaryPowerData, ISystemSummaryPriceData } from "../../../../shared/types/analytics/trading-analytics/ISystemSummaryData";
import { useColourModeContext } from "../../../ColourMode";
import { DateFormatter } from "../../helpers/DateFormatter";

const SystemSummaryChartTooltipRow: React.FC<ISystemSummaryChartTooltipRow> = ({ name, value, color, square }) => {
    return (
        <p>
            <Stack direction="row" spacing={0.5} alignItems="center">
                <span style={{ backgroundColor: color, width: "20px", height: square ? "20px" : "2px" }}></span>
                <span>{name}: {value} MW</span>
            </Stack>
        </p>
    );
}

interface ISystemSummaryChartTooltipRow {
    name: string,
    value: string,
    color: string,
    square: boolean
}
const SystemSummaryChartTooltip: React.FC<ISystemSummaryChartTooltip> = ({ data, powerKeys, priceKeys, enabledData, nameDict, coloursDict }) => {
    const colourMode = useColourModeContext();
    return (
            <Paper sx={{
                "borderRadius": 5,
                "border": (colourMode.isDarkMode ? "1px solid white" : "1px solid black"),
                px: 1,
                py: 0.5
            }}>
                <p>Date (Local Time): {DateFormatter(data.dateTime)}</p>
                <p>Power Values:</p>
                {powerKeys.map((key) => {
                    if (enabledData[key]) {
                        const name = nameDict[key]
                        const color = coloursDict[key]
                        const value = data[key].toFixed(0)
                        return (
                            <SystemSummaryChartTooltipRow key={"System-Summary-" + name} name={name} value={value} color={color} square={true} />
                        )
                    }
                    return (<></>)
                })}
                <p>Price Values:</p>
                {priceKeys.map((key) => {
                    if (enabledData[key]) {
                        const name = nameDict[key]
                        const color = coloursDict[key]
                        const value = data[key].toFixed(0)
                        return (
                            <SystemSummaryChartTooltipRow key={"System-Summary-" + name} name={name} value={value} color={color} square={false} />
                        )
                    }
                    return (<></>)
                })}
        </Paper>
    );
}
export interface ISystemSummaryChartTooltip {
    data: ISystemSummaryData,
    powerKeys: (keyof ISystemSummaryPowerData<any>)[],
    priceKeys: (keyof ISystemSummaryPriceData<any>)[],
    enabledData: ISystemSummaryCategories<boolean>,
    nameDict: ISystemSummaryCategories<string>,
    coloursDict: ISystemSummaryCategories<string>
}

export default SystemSummaryChartTooltip;