export interface ISiteStatus {
    siteId: string;
    siteName: string;
    siteRunningStatus: SiteRunningStatus;
    activePowerExport: number;
    activePowerExportLastThirtyMins: DateTimeResponse[]; 
    siteCapacity: number;
    availableExport: number;
    availableImport: number;
    dispatchPlan: number;
    dispatchPlanLastThirtyMins: DateTimeResponse[];
    minutesToDispatch: number;
    manualDispatchStartSignal: number;
    assetType: AssetType;
    commsHealthStatus: SiteCommsStatus;
    backgroundHexColour: string;
    textHexColour: string;
    siteManualControl: number;
    manualStart: boolean;
    manualStop: boolean;
    dch?: number;
    dcl?: number;
    dmh?: number;
    dml?: number;
    drh?: number;
    drl?: number; 
}

export interface DateTimeResponse {
    datetime: string,
    value: number
}

export enum AssetType {
    Gas = 0,
    Battery = 1,
    Diesel = 2,
    Solar = 3,
    Wind = 4
}

export enum SiteCommsStatus {
    Healthy = 0,
    OutOfService = 1,
    InMaintenance = 2,
    Warning = 3,
    Error = 4
}

export enum SiteRunningStatus
{
    NoPNNoRunAuto = 0,
    GeneratingToPNAuto = 1,
    UnScheduledRunAuto = 2,
    FailedPNRunAuto = 3,
    GeneratingToPNManual = 4,
    UnScheduledRunManual = 5,
    FailedPNRunManual = 6,
    NoPNNoRunManual = 7,
    ManualDispatch = 8,
    DNODispatch = 9,
    GeneratingToOfferAuto = 10,
    GeneratingToBidAuto = 11
}